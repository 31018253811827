/*const FirebaseConfig = {
  apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
  authDomain: 'emilus.firebaseapp.com',
  databaseURL: 'https://emilus.firebaseio.com',
  projectId: 'emilus',
  storageBucket: 'emilus.appspot.com',
  messagingSenderId: '807555350717',
  appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
  measurementId: 'G-8KE7HJB252'
};*/
const FirebaseConfig = {
  apiKey: "AIzaSyBmoIN0AE8UkVxexFTi0PPa1Pa9ZUzrlf0",
  authDomain: "cni-web.firebaseapp.com",
  databaseURL: 'https://cni-web-default-rtdb.firebaseio.com',
  projectId: "cni-web",
  storageBucket: "cni-web.appspot.com",
  messagingSenderId: "416646782035",
  appId: "1:416646782035:web:ff19521f922ab151f23aa6",
  measurementId: "G-1WVWW7RQ3C"
};

export default FirebaseConfig

