import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN,USER_LOGIN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import CNIService from 'services/CNIService';
//import AuthService from 'services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	//userLogin: JSON.parse(typeof localStorage.getItem(USER_LOGIN) === 'object'?localStorage.getItem(USER_LOGIN):"{}") || null,
	userLogin: JSON.parse(localStorage.getItem(USER_LOGIN)) || null,
	token: localStorage.getItem(AUTH_TOKEN) || null
}
const getDataUser = async (user,rolesUser) => {
	let getToken = null;
	// Obtener el token ID del usuario
	await user.getIdToken().then((token) => {
		getToken = token;
	});
	//console.log("Token ID:", getToken);
	localStorage.setItem(AUTH_TOKEN, getToken);
	const {displayName,email,photoURL} = user || {};
	const roles=rolesUser.roles.map(rol=>({id:rol.id,nombre:rol.nombre}))
	const userLogin ={displayName,email,photoURL,roles};
	localStorage.setItem(USER_LOGIN,JSON.stringify(userLogin));
	return {token:getToken,userLogin};
}

export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signInEmailRequest(email, password)
		if (response.user) {
			const user = response.user;
			const usuarioResponse = await CNIService.registrarPaciente({ uid:user.uid,email: user.email,displayName: user.displayName});
			let roles = null;
			if(usuarioResponse?.id||usuarioResponse?.usuarioId){
				roles = await CNIService.getRolesPorUsuario(usuarioResponse?.id||usuarioResponse?.usuarioId);
			}
			return await getDataUser(user,roles);
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signUpEmailRequest(email, password);
		if (response.user) {
			const user = response.user;
			const usuarioResponse = await CNIService.registrarPaciente({ uid:user.uid,email: user.email,displayName: user.displayName});
			let roles = null;
			if(usuarioResponse?.id||usuarioResponse?.usuarioId){
				roles = await CNIService.getRolesPorUsuario(usuarioResponse?.id||usuarioResponse?.usuarioId);
			}
			return getDataUser(user,roles);
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		console.log({err})
		return rejectWithValue(err.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    const response = await FirebaseService.signOutRequest()
		localStorage.removeItem(USER_LOGIN);
		localStorage.removeItem(AUTH_TOKEN);
    return response.data
})

export const listUsers = createAsyncThunk('auth/listUsers',async () => {
	const nextPageToken = 10000;
	const response = await FirebaseService.listUsers(1000, nextPageToken)
  .then((listUsersResult) => {
    listUsersResult.users.forEach((userRecord) => {
      // Multi-factor enrolled users second factors can be retrieved via:
      /*if (userRecord.multiFactor) {
        userRecord.multiFactor.enrolledFactors.forEach((enrolledFactor) => {
          console.log(userRecord.uid, enrolledFactor.toJSON());
        });
      }*/
    });
  })
  .catch((error) => {
    console.log('Error listing users:', error);
  });
	return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInGoogleRequest()
	if (response.user) {
		const user = response.user;
		const usuarioResponse = await CNIService.registrarPaciente({ uid:user.uid,email: user.email,displayName: user.displayName});
		let roles = null;
		if(usuarioResponse?.id || usuarioResponse?.usuarioId){
			roles = await CNIService.getRolesPorUsuario(usuarioResponse?.id || usuarioResponse?.usuarioId);
		}
		return await getDataUser(user,roles);
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInFacebookRequest()
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload.token
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.userLogin = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userLogin = action.payload.userLogin
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.userLogin = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.userLogin = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userLogin = action.payload.userLogin
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userLogin = action.payload.userLogin
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
} = authSlice.actions

export default authSlice.reducer