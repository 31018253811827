import fetch from "auth/FetchInterceptorCNI";

const cniService = {};

cniService.getUsers = function (params) {
  return fetch({
    url: "/users/usuariosUI",
    method: "get",
    params,
  }).catch((error) => {
    console.log({ error });
    return [];
  });
};

cniService.getPrivileges = function (params) {
  return fetch({
    url: "/privilegios",
    method: "get",
    params,
  }).catch((error) => {
    console.log({ error });
    return [];
  });
};

cniService.getRoles = function (params) {
  return fetch({
    url: "/roles",
    method: "get",
    params,
  }).catch((error) => {
    console.log({ error });
    return [];
  });
};

cniService.getPacientes = function (params) {
  return fetch({
    url: "/usuarios/pacientes",
    method: "get",
    params,
  }).catch((error) => {
    console.log({ error });
    return [];
  });
};

cniService.registrarPaciente = function (data) {
  return fetch({
    url: "/usuarios/registrarPaciente",
    method: "post",
    data: data,
  }).catch((error) => {
    console.log({ error });
    return [];
  });
};

cniService.getRolesPorUsuario = function (params) {
  return fetch({
    url: "/usuarios/"+params,
    method: "get"
  }).catch((error) => {
    console.log({ error });
    return [];
  });
};


export default cniService;
