import React from "react";
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  ADM_PUBLIC_PREFIX_PATH,
} from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "politica-privacidad",
    path: `${ADM_PUBLIC_PREFIX_PATH}/politica-privacidad`,
    component: React.lazy(() => import("views/adm/politica-privacidad-fb")),
  },
  {
    key: "condicion-servicio",
    path: `${ADM_PUBLIC_PREFIX_PATH}/condicion-servicio`,
    component: React.lazy(() => import("views/adm/condicion-servicio-fb")),
  },
  {
    key: "eliminar-datos-usuario",
    path: `${ADM_PUBLIC_PREFIX_PATH}/eliminar-datos-usuario`,
    component: React.lazy(() => import("views/adm/eliminar-datos-usuario-fb")),
  },
];
const nameApp = "cni";
const nameAdministrator = "administrator";

export const protectedRoutes = [
  {
    key: "dashboard.empty",
    path: `${APP_PREFIX_PATH}/dashboards/empty`,
    component: React.lazy(() => import("views/app-views/dashboards/empty")),
  },
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: `${nameAdministrator}.users`,
    path: `${APP_PREFIX_PATH}/${nameAdministrator}/users`,
    component: React.lazy(() => import("views/app-views/cni/users")),
  },
  {
    key: `${nameAdministrator}.roles`,
    path: `${APP_PREFIX_PATH}/${nameAdministrator}/roles`,
    component: React.lazy(() => import("views/app-views/cni/roles")),
  },
  {
    key: `${nameAdministrator}.privileges`,
    path: `${APP_PREFIX_PATH}/${nameAdministrator}/privileges`,
    component: React.lazy(() => import("views/app-views/cni/privileges")),
  },
  {
    key: `${nameAdministrator}.historyTypeGenerator`,
    path: `${APP_PREFIX_PATH}/${nameAdministrator}/historyTypeGenerator`,
    component: React.lazy(() => import("views/app-views/cni/historyTypeGenerator")),
  },
  {
    key: "cni.patients",
    path: `${APP_PREFIX_PATH}/${nameApp}/patients`,
    component: React.lazy(() => import("views/app-views/cni/patients")),
  },
  {
    key: "cni.medicalHistory",
    path: `${APP_PREFIX_PATH}/${nameApp}/medicalHistory`,
    component: React.lazy(() => import("views/app-views/cni/medicalHistory")),
  },
  {
    key: "cni.assessmentHistory",
    path: `${APP_PREFIX_PATH}/${nameApp}/assessmentHistory`,
    component: React.lazy(() =>
      import("views/app-views/cni/assessmentHistory")
    ),
  },
];
