import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY,AUTHENTICATED_ENTRY_EMPTY } from 'configs/AppConfig'

const PublicRoute = () => {

	const { token, userLogin } = useSelector(state => state.auth)
  const {email} = userLogin ||{};
	const validEmails = ['victord2exp@gmail.com','thaliachura.nutricion@gmail.com'];
	return token ? <Navigate to={ email&&validEmails.includes(email)?AUTHENTICATED_ENTRY:AUTHENTICATED_ENTRY_EMPTY} /> : <Outlet/>
}

export default PublicRoute