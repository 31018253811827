import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  ADM_PUBLIC_PREFIX_PATH,
} from "configs/AppConfig";
const nameApp = "cni";
const nameAdministrator = "administrator";

export const TIPO_PRIVILEGIO = {
  ADD: "ADD",
  EDIT: "EDIT",
  SHOW_REPORT: "SHOW_REPORT",
  DELETE: "DELETE",
};

export const GRUPOS = [
  { id: "1", name: "Dashboard" },
  { id: "2", name: "Usuario" },
  { id: "3", name: "Roles" },
  { id: "4", name: "Privilegios" },
  { id: "5", name: "Pacientes" },
  { id: "6", name: "Historial Clinico" },
  { id: "7", name: "Historial de Evaluaciones" },
  { id: "8", name: "Historial Clinico via Paciente" },
  { id: "9", name: "Historial de Evaluacion via Paciente" },
];
export const PRIVILEGES = [
  {
    id: "1",
    group: "1",
    name: "Dashboard",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: "views/app-views/dashboards/default",
  },

  { id: "2", group: "2", name: "Adicionar Usuario", type: TIPO_PRIVILEGIO.ADD },
  { id: "3", group: "2", name: "Editar Usuario", type: TIPO_PRIVILEGIO.EDIT },
  {
    id: "4",
    group: "2",
    name: "Eliminar Usuario",
    type: TIPO_PRIVILEGIO.DELETE,
  },
  {
    id: "5",
    group: "2",
    name: "Mostrar Usuarios",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
    path: `${APP_PREFIX_PATH}/${nameAdministrator}/users`,
    component: "views/app-views/cni/users",
  },

  { id: "6", group: "3", name: "Adicionar Rol", type: TIPO_PRIVILEGIO.ADD },
  { id: "7", group: "3", name: "Editar Rol", type: TIPO_PRIVILEGIO.EDIT },
  { id: "8", group: "3", name: "Eliminar Rol", type: TIPO_PRIVILEGIO.DELETE },
  {
    id: "9",
    group: "3",
    name: "Mostrar Roles",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
    path: `${APP_PREFIX_PATH}/${nameAdministrator}/roles`,
    component: "views/app-views/cni/roles",
  },

  {
    id: "10",
    group: "4",
    name: "Mostrar Privilegios",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
    path: `${APP_PREFIX_PATH}/${nameAdministrator}/privileges`,
    component: "views/app-views/cni/privileges",
  },

  {
    id: "11",
    group: "5",
    name: "Adicionar Paciente",
    type: TIPO_PRIVILEGIO.ADD,
  },
  {
    id: "12",
    group: "5",
    name: "Editar Pacientes",
    type: TIPO_PRIVILEGIO.EDIT,
  },
  {
    id: "13",
    group: "5",
    name: "Eliminar Pacientes",
    type: TIPO_PRIVILEGIO.DELETE,
  },
  {
    id: "14",
    group: "5",
    name: "Mostrar Pacientes",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
  },

  {
    id: "15",
    group: "6",
    name: "Adicionar Historial Clinico",
    type: TIPO_PRIVILEGIO.ADD,
  },
  {
    id: "16",
    group: "6",
    name: "Editar Historial Clinico",
    type: TIPO_PRIVILEGIO.EDIT,
  },
  {
    id: "17",
    group: "6",
    name: "Eliminar Historial Clinico",
    type: TIPO_PRIVILEGIO.DELETE,
  },
  {
    id: "18",
    group: "6",
    name: "Mostrar Historiales Clinicos",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
  },

  {
    id: "19",
    group: "7",
    name: "Adicionar Historial Evaluacion",
    type: TIPO_PRIVILEGIO.ADD,
  },
  {
    id: "20",
    group: "7",
    name: "Editar Historial Evaluacion",
    type: TIPO_PRIVILEGIO.EDIT,
  },
  {
    id: "21",
    group: "7",
    name: "Eliminar Historial Evaluacion",
    type: TIPO_PRIVILEGIO.DELETE,
  },
  {
    id: "22",
    group: "7",
    name: "Mostrar Historiales Evaluaciones",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
  },

  {
    id: "23",
    group: "8",
    name: "Adicionar Historial Clinico via Paciente",
    type: TIPO_PRIVILEGIO.ADD,
  },
  {
    id: "24",
    group: "8",
    name: "Mostrar Historiales Clinicos via Paciente",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
  },

  {
    id: "25",
    group: "9",
    name: "Adicionar Historial de Evaluacion via Paciente",
    type: TIPO_PRIVILEGIO.ADD,
  },
  {
    id: "26",
    group: "9",
    name: "Mostrar Historiales de Evaluaciones via Paciente",
    type: TIPO_PRIVILEGIO.SHOW_REPORT,
  },
];

export const ROLES = [
  {
    id: "1",
    name: "Administrador",
    privileges: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  },
  {
    id: "2",
    name: "Nutricionista",
    privileges: [
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
    ],
  },
  { id: "3", name: "Paciente", privileges: ["23", "24", "25", "26"] },
];
export const getTablePrivileges = () => {
  const groupsById = {};
  GRUPOS.forEach((group) => {
    groupsById[group.id] = group.name;
  });
  return PRIVILEGES.map((privileges) => ({
    ...privileges,
    group: groupsById[privileges.group],
  }));
};

export const getTableRoles = () => {
  const privileges = getTablePrivileges();
  const privilegesById = {};
  privileges.forEach((privilege) => {
    privilegesById[privilege.id] = privilege;
  });
  return ROLES.map((rol) => ({
    ...rol,
    privileges: rol.privileges.map((privilege) => privilegesById[privilege]),
  }));
};
